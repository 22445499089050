<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-text>
        <v-container>
          <p
            class="mb-8"
            v-html="
              $t('users.dialogs.blockUser.description', {
                name: user.name,
                surname: user.surname,
                email: user.email,
              })
            "
          ></p>
          <v-form ref="blockUserForm" v-model="valid">
            <label class="mt-8">{{
              $t("users.dialogs.blockUser.reason")
            }}</label>
            <v-textarea
              v-model="reason"
              :rules="required"
              :disabled="loading"
              auto-grow
              outlined
              rows="8"
              row-height="15"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-10">
        <v-spacer></v-spacer>
        <v-btn
          color="secodary"
          outlined
          class="mr-2"
          :disabled="loading"
          @click="close"
        >
          {{ $t("users.dialogs.blockUser.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="submit"
        >
          {{ $t("users.dialogs.blockUser.block") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'BlockUser',
  props: ['dialog', 'user', 'loading'],
  data () {
    return {
      valid: true,
      reason: '',
      required: [(v) => !!v.trim() || this.$t('validation.fieldRequired')]
    }
  },
  watch: {
    dialog () {
      this.reason = ''
    }
  },
  methods: {
    close () {
      this.$refs.blockUserForm.resetValidation()
      this.$emit('close')
    },
    submit () {
      this.$refs.blockUserForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit', this.reason)
      this.$refs.blockUserForm.resetValidation()
    }
  }
}
</script>
